import { Icon } from '@troon/icons';
import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { Show, mergeProps } from 'solid-js';
import type { IconName } from '@troon/icons';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	appearance?: 'default' | 'danger';
	class?: string;
	icon?: IconName;
}>;

export function MessageBar(input: Props) {
	const props = mergeProps({ appearance: 'default' }, input);

	return (
		<div
			class={twMerge(
				twJoin(
					'flex size-fit items-start gap-2 rounded px-4 py-3',
					props.appearance === 'danger' && 'bg-red-100 text-red-600',
					props.appearance === 'default' && 'bg-neutral-100',
				),
				props.class,
			)}
		>
			<Show when={props.icon}>{(name) => <Icon name={name()} class="relative top-px shrink-0" />}</Show>
			{props.children}
		</div>
	);
}
